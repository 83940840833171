import { useCallback, useState } from "react";
import ChannelAPI  from "../api/ChannelAPI";

const useChannel = () => {
    const [allChannel, SetAllChannel] = useState<[]>([]);
    const getAllChannel = useCallback(async () => {
        var result: any = await ChannelAPI().getChannels();
        if (result) {
            SetAllChannel(result ? result.data : []);
        }
    }, [SetAllChannel]);


    return [allChannel, getAllChannel] as const;
}

export default useChannel;