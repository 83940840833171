import httpClient from '../client';

const ChannelAPI = () => {
    return {
        getChannels: () => {
            return httpClient.get("Channel").then(response => {
                return response;
            })
        }
    }
}

export default ChannelAPI;