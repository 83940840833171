import { Box, Popover, styled } from "@mui/material"
import { iconLists } from "../../resources/name"

const RootBox = styled(Box)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: 720,
    padding: '16px 24px',
    gap: 4,
    justifyContent: 'center',
    alignItems: 'center'
}))

const IconImage = styled('img')(() => ({
    flex: '1 1 auto',
    maxWidth: 48,
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#eeffc7'
    }
}))

interface IPresetNamePopoverProps {
    open: boolean
    anchorEl: any
    onClose: () => void
    onSelect: (name: string) => void
}

function PresetNamePopover(props: IPresetNamePopoverProps) {
    const { open, anchorEl, onClose, onSelect } = props

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={(event: any) => {
                event.stopPropagation()
                onClose()
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
        >
            <RootBox>
                {iconLists.map((icon, idx) => (
                    <IconImage key={'icon_' + idx} src={icon.path} alt={icon.name} onClick={() => {
                        onSelect(icon.name)
                        onClose()
                    }} />
                ))}
            </RootBox>
        </Popover>
    )
}

export default PresetNamePopover