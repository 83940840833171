import { useEffect } from "react";
import { Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "../AppBarComponent/AppBar";
import Playlist from "../MainPage/Playlist";
import Controls from "./Controls";
import { Box } from "@mui/system";
import PlayListIcon from '@mui/icons-material/PlaylistPlay';
import Typography from '@mui/material/Typography';
import { history } from '../../helpers/history';
import TagChart from "./TagChart";

function ManagePage() {

    useEffect(() => {
        if (!localStorage.getItem('selectedChannel')) {
            history.push('/channel');
        }
    }, []);
    
    return (
        <div>
            <CssBaseline />
            <AppBar />
            <Box sx={{ padding: '48px 60px !important', overflow: 'auto', width: '100% !important', maxWidth: '100% !important' }}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item md={'auto'} sx={{ flex: {
                            lg: '0 0 500px !important',
                            xl: '0 0 700px !important'
                        }}}>
                            <Typography variant="h5" gutterBottom component="div">
                                <PlayListIcon /> PlayList
                            </Typography>
                            <Playlist hasPlayer={false} editMode={true} />
                        </Grid>
                        <Grid container item md={'auto'} sx={{ flex: '1 1 auto !important' }} direction="column">
                            <Grid item>
                                <Controls />
                            </Grid>
                            <Grid item>
                                <TagChart />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
}

export default ManagePage;