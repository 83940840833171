import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect } from "react";
import { usePlaylistContext } from "../contexts/playlist.context";
import { PlaylistItem } from "../model";
import { PlaylistEvent } from "../models/PlaylistEventMessage";
import signalRMQ from "../signalRMQ";

function usePlaylistHub() {
    const { channelName } = usePlaylistContext()

    useEffect(() => {
        let connection: HubConnection | undefined = undefined

        function tryInitConnection() {
            connection?.start().then(() => {
                connection?.send('SelectChannel', channelName)
            }).catch(() => {
                setTimeout(tryInitConnection, 5000)
            })
        }

        if (channelName) {
            connection = new HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_API_URL}/hubs/playlisthub`)
                .withAutomaticReconnect()
                .build()

            connection.on(PlaylistEvent.ChangeVolume, (volume: number) => {
                signalRMQ.next({
                    event: PlaylistEvent.ChangeVolume,
                    params: volume
                });
            });
            connection.on(PlaylistEvent.SetMusic, (id: string) => {
                signalRMQ.next({
                    event: PlaylistEvent.SetMusic,
                    params: id
                });
            });
            connection.on(PlaylistEvent.Reload, () => {
                signalRMQ.next({
                    event: PlaylistEvent.Reload,
                    params: null
                });
            });
            connection.on(PlaylistEvent.PlayMusic, () => {
                signalRMQ.next({
                    event: PlaylistEvent.PlayMusic,
                    params: null
                });
            });
            connection.on(PlaylistEvent.PauseMusic, () => {
                signalRMQ.next({
                    event: PlaylistEvent.PauseMusic,
                    params: null
                });
            });
            connection.on(PlaylistEvent.StopMusic, () => {
                signalRMQ.next({
                    event: PlaylistEvent.StopMusic,
                    params: null
                });
            });
            connection.on(PlaylistEvent.NotifyCurrentIndex, (index: number) => {
                signalRMQ.next({
                    event: PlaylistEvent.NotifyCurrentIndex,
                    params: index
                });
            });
            connection.on(PlaylistEvent.NotifyNewItem, (data: PlaylistItem) => {
                signalRMQ.next({
                    event: PlaylistEvent.NotifyNewItem,
                    params: data
                });
            });
            connection.on(PlaylistEvent.NotifyRemove, (removeIndex: number) => {
                signalRMQ.next({
                    event: PlaylistEvent.NotifyRemove,
                    params: removeIndex
                });
            });
            connection.on(PlaylistEvent.NotifyVolume, (volume: number) => {
                signalRMQ.next({
                    event: PlaylistEvent.NotifyVolume,
                    params: volume
                });
            });

            tryInitConnection();
        }

        return () => {
            connection?.stop();
        }
    }, [channelName])
}

export default usePlaylistHub