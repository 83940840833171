import { createContext, useContext } from "react";

export interface IPlaylistContext {
    channelName?: string | null
    deviceId: string
    deviceName: string
    changeDeviceName: (name: string) => void
    isOverlayLoading: boolean
    showGlobalLoading: (show: boolean) => void
}

export const PlaylistContext = createContext<IPlaylistContext>({
    channelName: '',
    deviceId: '',
    deviceName: '',
    isOverlayLoading: false,
    changeDeviceName() {},
    showGlobalLoading() {}
})

export const usePlaylistContext = () => useContext(PlaylistContext)