import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Router } from 'react-router';
import { history } from './helpers/history';
import PlaylistContextProvider from './PlaylistContextProvider';

ChartJS.register(ArcElement, Tooltip, Legend);

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <>
    <PlaylistContextProvider>
      <Router history={history}>
        <App />
      </Router>
      </PlaylistContextProvider>
  </>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
