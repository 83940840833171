import React, { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { Box } from "@mui/system";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Typography from '@mui/material/Typography';

import Divider from '@mui/material/Divider';

import YoutubeLinkModel from '../../models/YoutubeLinkModel';
import ManageAPI from '../../api/ManageAPI';

import signalRMQ from "../../signalRMQ"
import { PlaylistEvent, PlaylistEventMessage } from "../../models/PlaylistEventMessage"
import { usePlaylistContext } from "../../contexts/playlist.context";

//Confirm Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles({
    margin: {
        margin: 10
    },
    noPadding: {
        padding: 0
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
});

function Control() {
    const { deviceId, deviceName } = usePlaylistContext()

    const classes = useStyles();
    const [volume, setVolume] = useState<number>(80);
    const [youtubeLink, setYoutubeLink] = useState<string>("");
    const [openDialog, setOpenDialog] = useState(false);
    const { channelName } = usePlaylistContext()

    const handleVolumeChange = async (event: any, newValue: number | number[]) => {
        setVolume(newValue as number);
        await ManageAPI().setVolume(newValue as number);
        await ManageAPI().notifyNewVolume(newValue as number);
    };

    const handleYoutubeLinkChange = (evt: any) => {
        const newValue = evt.target.value;
        setYoutubeLink(newValue);
    };

    const handleClearTextFields = () => {
        setYoutubeLink("");
    };

    const submitYoutubeLink = async () => {
        let requestName = (deviceName ?? '').trim()
        if (requestName.toLowerCase() !== 'anonymous') {
            requestName = `♪${deviceName}.${deviceId[27]}${deviceId[3]}`
        }

        var data: YoutubeLinkModel = {
            link: youtubeLink,
            requestBy: requestName
        };

        await ManageAPI().addYoutubeLink(data);
        handleClearTextFields();
    }

    const playMusicHandle = () => {
        ManageAPI().playMusic();
    }

    const pauseMusicHandle = () => {
        ManageAPI().pauseMusic();
    }

    const stopMusicHandle = () => {
        ManageAPI().stopMusic();
    }

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const clearFloorPlaylist = async () => {
        var result = await ManageAPI().clearPlaylist();
        if (result && result.status !== 200) {
            alert("Error occurred while clear playlist. Please contact DJ developer team.");
        }
    }

    useEffect(() => {
        const subscription = signalRMQ.subscribe((message: PlaylistEventMessage) => {
            if (message.event === PlaylistEvent.NotifyVolume) {
                var syncVolume = message.params;
                setVolume(syncVolume);
            }
        });

        return () => {
            subscription.unsubscribe();
        }
    }, []);

    return (
        <Box className={classes.margin}>
            <Box style={{ marginBottom: 20 }}>
                <Stack direction="column" spacing={2}>
                    <TextField
                        label="Youtube URL"
                        value={youtubeLink}
                        fullWidth
                        onChange={handleYoutubeLinkChange}
                    />
                </Stack>
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" startIcon={<SendIcon />} onClick={submitYoutubeLink}>
                        Send
                    </Button>
                    <Button variant="outlined" startIcon={<DeleteIcon />} onClick={handleClearTextFields}>
                        Clear
                    </Button>
                </Stack>
            </Box>
            <Divider />
            <Box style={{ marginTop: 20 }}>
                <Typography variant="h6" gutterBottom component="div">
                    Video controls
                </Typography>
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" startIcon={<PlayCircleIcon />} onClick={playMusicHandle}>
                        Play
                    </Button>
                    <Button variant="outlined" startIcon={<PauseCircleIcon />} onClick={pauseMusicHandle}>
                        Pause
                    </Button>
                    <Button variant="outlined" startIcon={<StopCircleIcon />} onClick={stopMusicHandle}>
                        Stop
                    </Button>
                    <Box sx={{ width: 200 }}>
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <VolumeDown />
                            <Slider aria-label="Volume" value={volume} onChangeCommitted={handleVolumeChange} min={0} max={100} />
                            <VolumeUp />
                        </Stack>
                    </Box>
                </Stack>
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="error" startIcon={<DeleteSweepIcon />} onClick={handleClickOpenDialog}>
                        Clear current playlist
                    </Button>
                </Stack>
            </Box>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}>
                <DialogTitle id="alert-dialog-title">
                    {"Clear Playlist Confirmation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Do you want to clear current playlist at this floor (${channelName})?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={clearFloorPlaylist} autoFocus>Ok</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Control;