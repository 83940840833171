export interface PlaylistEventMessage {
    event: string
    params: any
}

export const PlaylistEvent = {
    ChangeVolume: 'ChangeVolume',
    SetMusic: 'SetMusic',
    Reload: "Reload",
    PlayMusic: "PlayMusic",
    PauseMusic: "PauseMusic",
    StopMusic: "StopMusic",
    NotifyCurrentIndex: "NotifyCurrentIndex",
    NotifyNewItem: "NotifyNewItem",
    NotifyRemove: "NotifyRemove",
    NotifyVolume: "NotifyVolume"
}