import { Avatar, Box, Typography } from "@mui/material"
import { useMemo } from "react"
import { usePlaylistContext } from "../../contexts/playlist.context"
import { getIconPathFromName } from "../../resources/name"

interface IUserButtonProps {
    onClick: () => void
}

function UserButton({ onClick }: IUserButtonProps) {
    const { deviceName } = usePlaylistContext()
    const iconPath = useMemo(() => getIconPathFromName(deviceName), [deviceName])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={onClick}>
            <Avatar alt="avatar" src={iconPath} sx={{ width: 48, height: 48, bgcolor: '#ccc' }} />
            <Typography component="span" sx={{ ml: 1 }}>{deviceName}</Typography>
        </Box>
    )
}

export default UserButton