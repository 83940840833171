
import { Box, Typography } from '@mui/material';
import StatAPI from 'api/StatAPI';
import { generateColor } from 'helpers/color';
import { PlaylistEvent, PlaylistEventMessage } from 'models/PlaylistEventMessage';
import Tag from 'models/Tag';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import signalRMQ from 'signalRMQ';

const options: any = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'right',
        },
    },
};

function TagChart() {
    const [tags, setTags] = useState<Tag[]>([])

    const data = useMemo(() => {
        return {
            labels: tags.map(t => `${t.name} (${t.count})`),
            datasets: [
                {
                    label: 'Tag Count',
                    data: tags.map(t => t.count),
                    backgroundColor: generateColor(tags.length),
                },
            ]
        }
    }, [tags])

    const reloadTag = useCallback(() => {
        StatAPI().getTagCount().then(res => {
            setTags(res.data.slice(0, 10))
        })
    }, [])

    useEffect(() => {
        reloadTag()
    }, [reloadTag])

    useEffect(() => {
        const subscription = signalRMQ.subscribe((message: PlaylistEventMessage) => {
            if (message.event === PlaylistEvent.NotifyNewItem) {
                reloadTag()
            }
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [reloadTag])

    if (tags.length === 0)
        return <div />

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Top 10 tags</Typography>
            <Pie 
                data={data} 
                options={options} 
                height={350} 
                width={800}
            />
        </Box>
    )
}

export default TagChart;