import httpClient from 'client';
import Tag from 'models/Tag';

const PREFIX = 'stat'

const StatAPI = () => {
    const channelName = localStorage.getItem('selectedChannel');

    return {
        getTagCount: () => httpClient.get<Tag[]>(`${PREFIX}/tag/${channelName}`)
    }
}

export default StatAPI;