import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import AppBar from "../AppBarComponent/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import { history } from '../../helpers/history';
import { makeStyles } from '@mui/styles';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import useChannel from "../../hooks/useChannel";
import Stack from '@mui/material/Stack';

const useStyles = makeStyles({
    margin: {
        margin: 10
    },
    noPadding: {
        padding: 0
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    buttonMarginTop: {
        marginTop: 10
    }
});

function FloorPage() {
    const classes = useStyles();
    const [allChannel, getAllChannel] = useChannel();
    const [selectedChannel, SetSelectedChannel] = useState<string>("");

    useEffect(() => {
        if (!localStorage.getItem('selectedChannel')) {
            history.push('/channel');
        }

        getAllChannel();
    }, [getAllChannel]);

    const handleChannelDropdow = (evt: any) => {
        const value = evt.target.value;
        SetSelectedChannel(value);
    }

    const setChannel = () => {
        if (selectedChannel) {
            localStorage.setItem('selectedChannel', selectedChannel);
            document.location = '/'
        }
    }

    return (
        <div>
            <CssBaseline />
            <AppBar />
            <Container fixed style={{ height: '100vh'}}>
                <Box className={classes.margin} height={"100%"} style={{justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '-10vh'}}>
                    <Grid container className={classes.noPadding} >
                        <Stack direction="column" spacing={2} width={"100%"}>
                            <TextField
                                select
                                fullWidth
                                label="Channel"
                                value={selectedChannel}
                                helperText="Please select your channel playlist"
                                onChange={handleChannelDropdow}>
                                {
                                    allChannel.map(channel => (
                                        <MenuItem key={channel} value={channel}>
                                            {channel}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                            <div className={classes.buttonContainer}>
                                <Button variant="contained" color="primary" onClick={setChannel}>
                                    Set Channel
                                </Button>
                            </div>
                        </Stack>
                    </Grid>
                </Box>
            </Container>
        </div>
    );
}

export default FloorPage;