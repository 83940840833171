import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from "@mui/material"
import { useCallback, useRef, useState } from "react"
import { usePlaylistContext } from "../../contexts/playlist.context"
import { names } from "../../resources/name"
import PresetNamePopover from "./PresetNamePopover"

interface IUserPreferenceProps {
    open: boolean
    onClose: () => void
}

function UserPreferenceDialog(props: IUserPreferenceProps) {
    const { open, onClose } = props
    const { changeDeviceName } = usePlaylistContext()
    const [pickPresetOpen, setPickPresetOpen] = useState(false)
    const [name, setName] = useState(localStorage.getItem('deviceName') ?? '')
    const pickPresetButtonRef = useRef<any>(null)

    const applyAnonymousName = useCallback(() => {
        setName('Anonymous')
    }, [])

    const applyRandomName = useCallback(() => {
        const x = Math.floor(Math.random() * names.length)
        setName(names[x])
    }, [])

    const saveChange = useCallback(() => {
        changeDeviceName(name)
        onClose()
    }, [name, changeDeviceName, onClose])

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogContent dividers>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <TextField 
                            label="Default Name"
                            placeholder="Default Name"
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </Grid>
                    <Grid container item spacing={1}>
                        <Grid item xs={4}>
                            <Button variant="contained" fullWidth onClick={applyAnonymousName}>Use Anonymous</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" fullWidth onClick={applyRandomName}>Random name</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button ref={pickPresetButtonRef} variant="contained" fullWidth onClick={() => setPickPresetOpen(true)}>Pick preset name</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <PresetNamePopover
                    open={pickPresetOpen}
                    anchorEl={pickPresetButtonRef.current}
                    onClose={() => setPickPresetOpen(false)}
                    onSelect={newName => setName(newName)}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={saveChange}>OK</Button>
                <Button variant="text" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserPreferenceDialog