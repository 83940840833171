import { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import UserButton from './UserButton';
import UserPreferenceDialog from './UserPreferenceDialog';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreDropdown from './MoreDropdown';
import { usePlaylistContext } from 'contexts/playlist.context';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
});

function AppBarComponent() {
    const classes = useStyles();
    const moreIconRef = useRef<any>(null)
    const { channelName } = usePlaylistContext()
    const [moreDropdownOpen, setMoreDropdownOpen] = useState(false)
    const [userDialogOpen, setUserDialogOpen] = useState(false)

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                        <Avatar src="./mycostech.png" />
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        Mycos Youtube Party ({channelName})
                    </Typography>
                    <UserButton onClick={() => setUserDialogOpen(true)}/>
                    <IconButton sx={{ ml: 2 }} ref={moreIconRef} onClick={() => setMoreDropdownOpen(true)}>
                        <MoreVertIcon fontSize="large" sx={{ color: '#fff' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <UserPreferenceDialog
                key={'d_' + userDialogOpen}
                open={userDialogOpen}
                onClose={() => setUserDialogOpen(false)}
            />
            <MoreDropdown
                open={moreDropdownOpen}
                onClose={() => setMoreDropdownOpen(false)}
                onSelectMenuItem={() => setMoreDropdownOpen(false)}
                anchorEl={moreIconRef.current}
            />
        </div>
    );
}

export default AppBarComponent;