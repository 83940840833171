import { List, ListItem, ListItemButton, ListItemText, Popover, PopoverProps } from "@mui/material"
import useExportPlaylist from "./useExportPlaylist"

interface IMoreDropdownProps extends PopoverProps {
    onSelectMenuItem: () => void
}

function MoreDropdown(props: IMoreDropdownProps) {
    const { importPlaylist, exportPlaylist } = useExportPlaylist()

    return (
        <Popover
            {...props}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        importPlaylist()
                        props.onSelectMenuItem()
                    }}>
                        <ListItemText primary="Import Playlist" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        exportPlaylist()
                        props.onSelectMenuItem()
                    }}>
                        <ListItemText primary="Export Playlist" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Popover>
    )
}

export default MoreDropdown