import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { PlaylistItem } from "../../model"
import PlaylistRow from "./PlaylistRow"
import { makeStyles } from '@mui/styles';

//Table
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import signalRMQ from "../../signalRMQ"
import { PlaylistEvent, PlaylistEventMessage } from "../../models/PlaylistEventMessage"
import ManageAPI from '../../api/ManageAPI'

interface IProps {
    hasPlayer: boolean;
    editMode?: boolean
}

const useStyles = makeStyles({
    playlistScrollable: {
        height: "100%",
        overflow: "auto"
    },
});

function Playlist({ hasPlayer, editMode = false }: IProps) {
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [items, setItems] = useState<PlaylistItem[]>([]);
    const [currentVideoId, setCurrentVideoId] = useState<string>("");
    const [screenHeight, SetScreenHeight] = useState<number>(hasPlayer ? window.innerHeight : (window.innerHeight - 150)); //150 = AppBar + Top Padding
    const classes = useStyles();

    (window as any).setPlayerEventListener((event: any) => {
        if (event === 'videoEnd') {
            if (items.length === 0)
                return

            let nextIndex = currentIndex
            if (nextIndex === -1)
                nextIndex = 0
            else if (nextIndex < items.length - 1)
                nextIndex = nextIndex + 1;
            else
                nextIndex = 0

            if (nextIndex !== currentIndex) {
                ManageAPI().setCurrent(nextIndex).then(() => {
                    console.log('updated server playlist index')
                })
                setCurrentIndex(nextIndex)
                setCurrentVideoId(items[nextIndex].videoId);
            }
        }
    });

    (window as any).onresize = (() => {
        setTimeout(() => {
            SetScreenHeight(hasPlayer ? window.innerHeight : (window.innerHeight - 150)); //150 = AppBar + Top Padding
        }, 100);
    });

    useEffect(() => {
        const subscription = signalRMQ.subscribe((message: PlaylistEventMessage) => {
            if (message.event === PlaylistEvent.ChangeVolume) {
                if (hasPlayer)
                    (window as any).changePlayerVolume(message.params)
            }
            if (message.event === PlaylistEvent.SetMusic) {
                const id: string = message.params;
                if ((window as any).player) {
                    var index = items.findIndex(x => x.videoId === id);
                    if (index >= 0) {
                        setCurrentVideoId(id);
                        setCurrentIndex(index);
                        if (hasPlayer)
                            (window as any).playVideo(id);
                    }
                }
            }
            if (message.event === PlaylistEvent.Reload) {
                window.location.reload()
            }
            if (message.event === PlaylistEvent.PlayMusic) {
                if ((window as any).player && hasPlayer)
                    (window as any).player.playVideo();
            }
            if (message.event === PlaylistEvent.PauseMusic) {
                if ((window as any).player && hasPlayer)
                    (window as any).player.pauseVideo();
            }
            if (message.event === PlaylistEvent.StopMusic) {
                if ((window as any).player && hasPlayer)
                    (window as any).player.stopVideo();
            }
            if (message.event === PlaylistEvent.NotifyCurrentIndex) {
                const index: number = message.params
                setCurrentVideoId(items[index].videoId);
            }
            if (message.event === PlaylistEvent.NotifyNewItem) {
                var data: PlaylistItem = message.params;

                setItems(prev => {
                    return [...prev, data]
                });
            }
            if (message.event === PlaylistEvent.NotifyRemove) {
                console.log(message.params)

                if(items.length > 1){
                    let removeIndex: number = message.params;
                    

                    setItems(prev => {
                        const updatedList = prev.filter((e, idx) => idx !== removeIndex)
                        return updatedList
                    });
                }
                else{
                    window.location.reload();
                }
            }
        })

        return () => {
            subscription.unsubscribe();
        }
    }, [items, hasPlayer, currentIndex]);

    useEffect(() => {
        ManageAPI().getPlaylist().then(res => {
            function waitForPlayerReady(videoId: any) {
                if ((window as any).playerReady) {
                    (window as any).playVideo(videoId)
                }
                else {
                    setTimeout(() => {
                        waitForPlayerReady(videoId)
                    }, 100);
                }
            }

            if (res) {
                const data = res.data as any;
                if (!data.items || data.items.length === 0)
                    return

                setCurrentIndex(data.currentIndex);
                setItems(data.items);
                setCurrentVideoId((data.currentIndex > -1) ? data.items[data.currentIndex].videoId : "");

                if (data.currentIndex >= 0) {
                    waitForPlayerReady(data.items[data.currentIndex].videoId)
                }
            }
        })
    }, []);


    const onMusicClick = (currIdx: number, videoId: string) => {
        setCurrentIndex(currIdx)
        setCurrentVideoId(videoId)
        ManageAPI().setCurrent(currIdx).then(() => {
            console.log('updated server playlist index')
        })
    }

    return (
        <Grid container>
            <Box sx={{ width: '100%' }} style={{ maxHeight: screenHeight }}>
                <nav aria-label="main mailbox folders" className={classes.playlistScrollable}>
                    <List className={`playlist-container`}>
                        {
                            items.map((item, idx) => {
                                return <PlaylistRow
                                    key={item.videoId}
                                    item={item}
                                    currentVideoId={currentVideoId}
                                    currIdx={idx}
                                    onMusicClick={onMusicClick}
                                    showControl={editMode}
                                />
                            })
                        }
                    </List>
                </nav>
            </Box>
        </Grid>
    )
}

export default Playlist