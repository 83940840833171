import React from "react";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { history } from '../../helpers/history';

function Navigation() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleManagementClick = () => {
        history.push('/manage');
    }

    const handleChannelClick = () => {
        history.push('/channel');
    }

    return (
        <div style={{position: 'fixed', bottom: 10}}>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Menubar">
                    <IconButton onClick={handleClick} size="medium" sx={{ ml: 2 }}>
                        <WidgetsIcon style={{color: 'white'}} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}>
                <MenuItem onClick={handleManagementClick}>
                    <ListItemIcon><ArtTrackIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>Management page</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleChannelClick}>
                    <ListItemIcon><DesktopMacIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>Channel page</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default Navigation;