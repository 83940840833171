import ManageAPI from "api/ManageAPI"
import { usePlaylistContext } from "contexts/playlist.context";
import ImportPlaylistContract from "models/ImportPlaylistContract";
import Playlist from "models/Playlist";
import { useCallback } from "react"

function openDownloadJsonWindow(data: any) {
    const filename = "playlist.json";
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.click();

    // clean up
    URL.revokeObjectURL(url);
}

function useExportPlaylist() {
    const { showGlobalLoading } = usePlaylistContext()

    const exportPlaylist = useCallback(() => {
        return ManageAPI().getPlaylist().then(res => {
            const playlist = res?.data
            if (playlist) {
                openDownloadJsonWindow(playlist)
            }
        })
    }, [])

    const importPlaylist = useCallback(() => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".json";

        input.onchange = (event: any) => {
            if (event.target.files.length <= 0)
                return
            
            const file = event.target.files[0]
            const reader = new FileReader()
            reader.readAsText(file);

            reader.onload = async () => {
                const json = reader.result as string
                const data = JSON.parse(json) as Playlist
                
                // process the imported data as needed
                const payload: ImportPlaylistContract = {
                    items: data.items.map(i => ({
                        requestedBy: i.requestedBy,
                        videoId: i.videoId,
                    }))
                } 
                
                showGlobalLoading(true)
                await ManageAPI().importPlaylist(payload)
                showGlobalLoading(false)
            };
        };

        input.click();
    }, [showGlobalLoading])

    return {
        exportPlaylist,
        importPlaylist,
    } as const
}

export default useExportPlaylist