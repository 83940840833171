import { CssBaseline } from '@mui/material';

function Player() {
    var square1 = {"--i": 1} as React.CSSProperties;
    var square2 = {"--i": 2} as React.CSSProperties;
    var square3 = {"--i": 3} as React.CSSProperties;
    var square4 = {"--i": 4} as React.CSSProperties;
    var square5 = {"--i": 5} as React.CSSProperties;
    return (
        <section className={`container`}>
            <CssBaseline />
            {/* <div className={`bg-color`}></div>
            <div className={`bg-color`}></div>
            <div className={`bg-color`}></div> */}
            <div className={`box`}>
                <div className={`video-container`}>
                    <div className={`square`} style={square1}></div>
                    <div className={`square`} style={square2}></div>
                    <div className={`square`} style={square3}></div>
                    <div className={`square`} style={square4}></div>
                    <div className={`square`} style={square5}></div>
                    <div className={`iframe-container`}>
                        <div id="player"></div>
                    </div>
                </div>
            </div>
            
        </section>
       
    )
}

export default Player