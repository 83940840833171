import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import Player from "./Player";
import Playlist from "./Playlist";
import CssBaseline from "@mui/material/CssBaseline";
import { history } from '../../helpers/history';
import Navigation from './Navigation';

function MainPage() {

    useEffect(() => {
        if (!localStorage.getItem('selectedChannel')) {
            history.push('/channel');
        }
    }, []);

    return (
        <section className={`gradient-bg`}>
            <CssBaseline />
            <Grid container>
                <Grid item md={'auto'} sx={{ flex: '1 1 auto !important' }}>
                    <Player />
                </Grid>
                <Grid item md={'auto'} sx={{ flex: '0 0 700px !important' }}>
                    <Playlist hasPlayer={true} />
                </Grid>
            </Grid>
            <Navigation />
        </section>
    );
}

export default MainPage;