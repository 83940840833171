import { Route, Switch } from "react-router";
import MainPage from "./components/MainPage/MainPage";
import ManagePage from "./components/ManagePage/ManagePage";
import ChannelPage from "./components/ChannelPage/ChannelPage";
import './assets/main.css';
import usePlaylistHub from "./hooks/usePlaylistHub";
import { usePlaylistContext } from "./contexts/playlist.context";
import { Backdrop, CircularProgress, Typography } from "@mui/material";

function App() {
    usePlaylistHub()
    const { channelName, isOverlayLoading } = usePlaylistContext()

    if (!channelName) {
        return <ChannelPage />
    }

    return (
        <>
            <Switch>
                <Route path="/" exact={true} component={MainPage} />
                <Route path="/home" exact={true} component={MainPage} />
                <Route path="/manage" exact={true} component={ManagePage} />
                <Route path="/channel" exact={true} component={ChannelPage} />
                <Route path="*" exact={true} component={ChannelPage} />
            </Switch>
            {
                isOverlayLoading && (
                    <Backdrop open onClick={() => {}} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CircularProgress color="primary" />
                        <Typography variant="h4">Please wait...</Typography>
                    </Backdrop>
                )
            }
        </>
    );
}

export default App;
