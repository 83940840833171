import { PlaylistItem } from "../../model"
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageAPI from '../../api/ManageAPI';
import { Box, IconButton } from "@mui/material";
import { useCallback, useMemo } from "react";
import { getIconFileName } from "../../resources/name";

interface IProps {
    item: PlaylistItem,
    currentVideoId: string
    currIdx: number;
    onMusicClick: any;
    showControl?: boolean
}

function PlaylistRow({ item, currentVideoId, currIdx, onMusicClick, showControl = false }: IProps) {
    const handleOpenYoutubeLink = useCallback(() => {
        window.open(`https://www.youtube.com/watch?v=${item.videoId}`, '_blank')
    }, [item.videoId])

    const handleRemove = useCallback(() => {
        ManageAPI().removeItem(currIdx)
    }, [currIdx])

    const setMusicHandle = (id: string) => {
        onMusicClick(currIdx, currentVideoId)
        ManageAPI().setMusic(id);
    }

    const iconName = useMemo(() => getIconFileName(item.requestedBy), [item.requestedBy])

    return (
        <ListItem className={`playlist-item ${(currentVideoId === item.videoId) ? "active" : ""}`}>
            {
                showControl &&
                (
                    <Box sx={{     
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        zIndex: 8000,
                    }}>
                        <IconButton onClick={handleOpenYoutubeLink}>
                            <OpenInNewIcon />
                        </IconButton>
                        <IconButton onClick={handleRemove}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                )
            }
            <CssBaseline />
            <ListItemButton onClick={() => setMusicHandle(item.videoId)}>
                <ListItemAvatar sx={{ mr: 2 }}>
                    {
                        item.thumbnailUrl ? 
                        (
                            <img src={item.thumbnailUrl} alt="thumbnail" style={{ width: 120, height: 'auto' }} />
                        ) :
                        (
                            <img src="/ei.png" alt="thumbnail" style={{ width: 120, height: 'auto' }} />
                        )
                    }                
                    {/* <Avatar>
                        <AlbumIcon color={(currentVideoId === item.videoId) ? "primary" : "disabled"} />
                    </Avatar> */}
                </ListItemAvatar>
                <ListItemText>
                    {item.title} Length: {item.length}, from:{' '}
                    {
                        iconName &&
                        (
                            <img src={`/icons/${iconName}_Icon.webp`} alt="avatar" style={{ width: 32 }} />
                        )
                    }
                    {item.requestedBy}
                </ListItemText>
            </ListItemButton>
        </ListItem>
    )
}

export default PlaylistRow