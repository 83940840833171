import { PlaylistContext } from "./contexts/playlist.context"
import { names } from "./resources/name"
import { v4 } from 'uuid'
import { useCallback, useState } from "react"

const defaultChannelName = localStorage.getItem('selectedChannel')

function generateNewDeviceId() {
    const id = v4().replace(/-/g, '')
    localStorage.setItem('deviceId', id)

    return id
}

function generateNewDeviceName() {
    const name = names[Math.floor(Math.random() * names.length)] 
    localStorage.setItem('deviceName', name)
    
    return name
}

const presetDeviceName = localStorage.getItem('deviceName')
const deviceId = localStorage.getItem('deviceId') ?? generateNewDeviceId()

function PlaylistContextProvider({ children }: { children: React.ReactNode }) {
    const [deviceName, setDeviceName] = useState(presetDeviceName ?? generateNewDeviceName())
    const [isOverlayLoading, setIsOverlayLoading] = useState(false)

    const changeDeviceName = useCallback((name: string) => {
        localStorage.setItem('deviceName', name ?? '')
        setDeviceName(name)
    }, [])

    return (
        <PlaylistContext.Provider value={{
            channelName: defaultChannelName,
            deviceId,
            deviceName,
            isOverlayLoading,
            changeDeviceName,
            showGlobalLoading: setIsOverlayLoading,
        }}>
            {children}
        </PlaylistContext.Provider>
    )
}

export default PlaylistContextProvider